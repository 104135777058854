import { batch } from "react-redux";
import { ProjectUrl } from "~/env";
import {
	COURIER_TRACK_SET_DATA,
	COURIER_TRACK_APPEND_DATA,
	COURIER_TRACK_SET_PAGE_NO,
	COURIER_TRACK_SET_FILTER_DATA,
	COURIER_TRACK_SET_AWB_OBJECT_ITEM,
	COURIER_TRACK_SET_TRACK_ITEM_LIST_DATA,
	COURIER_TRACK_ORDER_CLEAR_DATA,
	TRACK_ORDER_SET_MODAL_DATA,
	COURIER_TRACK_SET_AWB_NOTE,
	COURIER_TRACK_SET_TRACKING_DATA,
	TRACK_ORDER_NDR_ACTION,
	CLEAR_TRACK_ORDER_SCREEN
} from "./action-types";

//clear the data (clear modal data)
export const CourierTrackOrderClearData = () => ({ type: COURIER_TRACK_ORDER_CLEAR_DATA });

export const ClearTrackOrderStore = () => ({ type: CLEAR_TRACK_ORDER_SCREEN });

//set the awb object when click on the button and the modal opens
export const CourierTrackSetAwbObject = (item) => ({ type: COURIER_TRACK_SET_AWB_OBJECT_ITEM, item: item });

//sets the modal data
export const TrackOrderSetModalData = (name, value) => ({ type: TRACK_ORDER_SET_MODAL_DATA, name: name, value: value });

//sets the filter data
export const CourierTrackOrdersSetFilterData = (name, value) => ({ type: COURIER_TRACK_SET_FILTER_DATA, name: name, value: value });

//append the paging
export const CourierTrackSetPageNo = () => (dispatch, getState) => {
	dispatch({ type: COURIER_TRACK_SET_PAGE_NO });
	return dispatch(CourierTrackGetData("append"));
};

//get the print state and render it on the screen
export const CourierTrackGetPrintState = (awb_state) => () => {
	var state = "";
	if (awb_state == "40") state = "Delivered";
	else if (awb_state == "55") state = "RTO Delivered";
	else if (awb_state == "90") state = "Lost";
	else if (awb_state == "99") state = "Damaged";
	return state;
};

//get the initial data
export const CourierTrackGetInitialData = () => (dispatch, getState) => {
	nanobar.go(50);

	fetch(`${ProjectUrl}/orders/get-initial-couriers`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify()
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: COURIER_TRACK_SET_DATA, data: data });
			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
		});
};

export const CourierTrackGetData = (type) => (dispatch, getState) => {
	const { partner, courier_state, page_size, page_no, sort_by, search, website_filter } = getState().track_order_store;
	nanobar.go(50);

	return fetch(`${ProjectUrl}/orders/get-awb-info`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ partner, page_size, page_no, sort_by, search, state: courier_state, website_filter })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (type === "append") dispatch({ type: COURIER_TRACK_APPEND_DATA, data: data });
			else dispatch({ type: COURIER_TRACK_SET_DATA, data: data });
			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
		});
};

//get the tracking data and render it on the modal
export const CourierTrackGetTrackingData = (awb) => (dispatch, getState) => {
	nanobar.go(50);

	return fetch(`${ProjectUrl}/apiget/track-awb`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ awb: awb })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: COURIER_TRACK_SET_TRACKING_DATA, data: data });
			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

//get the courier item_list and render it on the modal
export const CourierTrackGetTrackItemListData = (awb) => (dispatch, getState) => {
	nanobar.go(50);

	return fetch(`${ProjectUrl}/orders/track-item-list`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ awb })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: COURIER_TRACK_SET_TRACK_ITEM_LIST_DATA, data: data });
			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

//fetch for update the status
export const CourierTrackUpdateStatus = () => (dispatch, getState) => {
	const {
		select_item: { awb_id },
		modal_data: { state, reason },
		rto_locked_permission: rto_locked_permission
	} = getState().track_order_store;

	var confirm_msg;
	if (state == "52" && !rto_locked_permission) {
		alertify.error("You don't have any rights to change status as RTO Locked");
	}
	if (state == "52") confirm_msg = confirm("Do you really want to RTO Locked this AWB ?");
	if (confirm_msg || state != "52") {
		nanobar.go(50);

		return fetch(`${ProjectUrl}/orders/change-status`, {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ awb_id, state, reason })
		})
			.then((res) => (res.ok ? res.text() : Promise.reject(res)))
			.then(() => {
				dispatch({ type: COURIER_TRACK_ORDER_CLEAR_DATA });
				nanobar.go(100);
				alertify.success("AWB status changed successfully");
			})
			.catch((err) => {
				nanobar.go(100);
				err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
				return Promise.reject();
			});
	} else {
		return Promise.reject();
	}
};

//fetch for updater the awb notes
export const CourierTrackAwbUpdateNote = () => (dispatch, getState) => {
	const {
		select_item: { awb },
		modal_data: { notes }
	} = getState().track_order_store;

	nanobar.go(50);

	return fetch(`${ProjectUrl}/orders/update-awb-note`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ awb, notes })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then((data) => {
			batch(() => {
				dispatch({ type: COURIER_TRACK_SET_AWB_NOTE, notes: data });
				dispatch({ type: COURIER_TRACK_ORDER_CLEAR_DATA });
			});

			nanobar.go(100);
			alertify.success("AWB notes updated successfully");
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

//fetch for update rto reason
export const CourierTrackRtoUpdateReason = () => (dispatch, getState) => {
	const {
		select_item: { awb_id },
		modal_data: { reason_id }
	} = getState().track_order_store;

	nanobar.go(50);

	return fetch(`${ProjectUrl}/orders/update-rto-reason`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ awb_id, reason_id })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then((data) => {
			dispatch(CourierTrackGetData());
			dispatch({ type: COURIER_TRACK_ORDER_CLEAR_DATA });
			nanobar.go(100);
			alertify.success("AWB RTO reason updated successfully");
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

//fetch for cancel pickup
export const CourierTrackCancelPickup = (awb, awb_id) => (dispatch, getState) => {
	if (!confirm("Do you really want to cancel the pickup and demanifest the following AWB " + awb + "?")) return;

	nanobar.go(50);

	fetch(`${ProjectUrl}/orders/cancel-pickup`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ awb_id })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then(() => {
			dispatch(CourierTrackGetData());
			alertify.success("Pickup cancelled");
			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
		});
};

//fetch for unassociate delivery agent
export const CourierTrackUpdateDeliveryAgent = (awb, awb_id) => (dispatch, getState) => {
	if (!confirm("Do you really want to unassociate this delivery agent" + "?")) return;
	nanobar.go(50);

	fetch(`${ProjectUrl}/orders/unassociate-delivery-agent`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ awb_id })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then(() => {
			dispatch(CourierTrackGetData());
			alertify.success("Delivery agents successfully unassociated");
			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
		});
};

//fetch for get the awb history list
export const courierNdrGetAwbNdrList = (awb, awb_id) => (dispatch, getState) => {
	nanobar.go(50);

	return fetch(`${ProjectUrl}/ndr/get-awb-ndr-list`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ awb_id })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: TRACK_ORDER_NDR_ACTION, data: data, awb: awb, awb_id: awb_id });
			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};
