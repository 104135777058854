import { useState } from "react";
import { useLocation } from "wouter";

export const useLoader = (initialState) => {
	const [loader, setLoader] = useState(initialState);
	const startLoader = () => setLoader(true);
	const endLoader = () => setLoader(false);
	return [loader, startLoader, endLoader];
};

export const useCustomLocation = () => {
	const [location, setLocation] = useLocation();
	const setCustomLocation = (url) => {
		if (url.includes(window.location.host)) {
			if (url.includes("://")) url = url.replace(window.location.origin, "");
			else url = url.replace(window.location.host, "");
		}
		if (url.includes("://")) window.location.href = url;
		else setLocation(url);
	};
	return [location, setCustomLocation];
};
