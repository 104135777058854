import { ProjectUrl } from "~/env";

import { GetWebsitesData } from "~/actions/common-actions";

import {
	LOGOUT_RESET_INITIAL_STATE,
	LOGIN_SET_ERROR_LIST,
	LOGIN_SET_EMAIL_OR_PASSWORD,
	LOGIN_REMOVE_ERROR_LIST,
	LOGIN_USER_GET_DATA
} from "~/actions/action-types";

/**
 * @description it removes the error message from the field when user focus(click) on that input field
 * @param {String} field "name" of the field it may be email field or password field
 */
export const LoginFocusField = (field) => ({ type: LOGIN_REMOVE_ERROR_LIST, field: field });

/**
 * @description it sets the value of the input field to the reducer
 * @param {string} field "name" of that field
 * @param {string} value value of the field
 */
export const LoginSetEmailOrPassword = (field, value) => ({ type: LOGIN_SET_EMAIL_OR_PASSWORD, field: field, value: value });

/**
 * @description it logouts the user from the website and resets the user data from the reducer also
 */
export const UserLogout = () => (dispatch) => {
	return fetch(ProjectUrl + "/home/logout", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? dispatch({ type: LOGOUT_RESET_INITIAL_STATE }) : Promise.reject(res)))
		.catch((err) => {
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

/**
 * @description Validate the user Details and then Signed in.
 */
export const LoginGetUserLogin = () => (dispatch, getState) => {
	let { email, password, error_list } = getState();
	let validation = _ValidateUser(error_list, email, password)(dispatch);
	if (validation !== true) return Promise.reject();

	return fetch(ProjectUrl + "/home/user-login", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ email: email, password: password })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then((data) => {
			console.log(data);
			if (data) window.location.href = `${ProjectUrl}${data}`;
			else throw new Error("The password / email seems to be incorrect");

			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

/**
 *
 * @param {*} error_list object of the error on the input fields
 * @param {*} email user inputed email
 * @param {*} password user inputed password
 * @returns true/false, true when all the fields are corrects , false when there is some error on the fields
 */
const _ValidateUser = (error_list, email, password) => (dispatch) => {
	let validation = true;
	let email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;

	if (!email && email === "") {
		error_list["email"] = "Email is required";
	} else if (!!email && !email_validator.test(email)) {
		error_list["email"] = "Email is not valid";
	}
	if (password === "") {
		error_list["password"] = "Password cannot be empty";
	} else if (password.length < 6) {
		error_list["password"] = "Password cannot be less than 6 characters";
	}

	if (Object.keys(error_list).length > 0) validation = false;
	dispatch({ type: LOGIN_SET_ERROR_LIST, error_list: error_list });
	return validation;
};

/**
 * @description get the login details of the users like name and the short_name
 */
export const LoginGetUserDetails = () => (dispatch, getState) => {
	fetch(`${ProjectUrl}/home/get-user-details`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify()
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (data.logged_in_success) {
				dispatch({ type: LOGIN_USER_GET_DATA, data: data });
				dispatch(GetWebsitesData());
			}
		})
		.catch((err) => {
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
		});
};
