import { memo } from "react";
const LabelValue = ({ label, value, className, value_class, label_class = "w-24", children }) => {
	return (
		<div className={`flex py-0.5 ${className}`}>
			<div className={`text-gray-medium leading-4 ${label_class}`}>{label}</div>
			<div className={`flex-1 leading-4 ${value_class}`}>
				{value}
				{children}
			</div>
		</div>
	);
};

export default memo(LabelValue);
