import {
	LOGIN_REMOVE_ERROR_LIST,
	LOGIN_SET_EMAIL_OR_PASSWORD,
	LOGIN_SET_ERROR_LIST,
	LOGIN_USER_GET_DATA,
	LOGOUT_RESET_INITIAL_STATE
} from "~/actions/action-types";

const INITIAL_STATE = {
	email: "",
	password: "",
	error_list: {}, //object for storing the errors of input fields
	datalist: { name: "", short_name: "", page_list: [] }
};

const LoginReducer = (state = INITIAL_STATE, action) => {
	let newState = Object.assign({}, state);

	//Set the Email and Password details of the user
	if (action.type == LOGIN_SET_EMAIL_OR_PASSWORD) {
		if (action.field === "email") {
			newState.email = action.value;
		} else if (action.field === "password") {
			newState.password = action.value;
		}
		return newState;
	}

	//set the key error_list object when there is some error on the password or email fields
	if (action.type === LOGIN_SET_ERROR_LIST) {
		newState.error_list = { ...newState.error_list, ...action.error_list };
		return newState;
	}

	//remove the key from the error_list object is
	if (action.type === LOGIN_REMOVE_ERROR_LIST) {
		newState.error_list = { ...newState.error_list };
		delete newState.error_list[action.field];
		return newState;
	}

	//reset the initial state after the login
	if (action.type == LOGOUT_RESET_INITIAL_STATE) {
		return INITIAL_STATE;
	}

	if (action.type === LOGIN_USER_GET_DATA) {
		newState.datalist = action.data;
		return newState;
	}

	return state;
};

export default LoginReducer;
