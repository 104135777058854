import React from "react";
import { connect } from "react-redux";

import Modal from "@bit/healthmug.admin.modal";
import NoData from "@bit/healthmug.admin.no-data";

import Card from "@bit/healthmug.admin.card";
import Grid from "@bit/healthmug.admin.grid";
import LabelValue from "~/components/common/label-value";

import { CourierTrackOrderClearData } from "~/actions/track-orders-actions";

const TrackOrdersNdrActionModal = (props) => {
	const clearUploadModalData = () => {
		props.Courier_Track_Order_Clear_Data();
	};

	return (
		<>
			<Modal id="openNdrActionModal" closeButton onCloseButtonClick={clearUploadModalData} small>
				<div className="text-lg text-gray-dark pt-3 pb-1 px-3">AWB No: {props.select_item.awb}</div>

				{props.ndr_actions?.length > 0 ? (
					<div className="overflow-y-auto pt-4">
						{props.ndr_actions.map((ndr, i) => {
							return (
								<Card className="mb-4 mx-4" key={i} shadow border>
									<Grid responsive cols={3}>
										<Grid.Row className="gap-x-4">
											<Grid.Col className="mb-4">
												<div className="font-medium">NDR Reason</div>
												<div className="text-sm mb-2 text-gray-dark">{ndr.ndr_reason}</div>
												<LabelValue label="NDR Date" value={ndr.ndr_date} />
												<LabelValue label="NDR Created" value={ndr.created_date} />
											</Grid.Col>
											<Grid.Col className="mb-4">
												{ndr.action != null ? (
													<>
														<div className="font-medium">NDR Action</div>
														<div>{ndr.action}</div>
														<LabelValue label="Action By" value={ndr.action_by} />
														<LabelValue label="Action Date" value={ndr.action_date} />
													</>
												) : null}
											</Grid.Col>
											<Grid.Col className="mb-4">
												{ndr.action != null ? (
													<div className="uk-margin-small-top">
														<div className="font-medium">Reattempt/Remarks</div>
														{ndr.reattempt_date ? <div className="text-gray-medium">{ndr.reattempt_date}</div> : null}
														<div>{ndr.comments}</div>
													</div>
												) : null}
											</Grid.Col>
										</Grid.Row>
									</Grid>
								</Card>
							);
						})}
					</div>
				) : (
					<NoData title="Data Not Found!!" sub_title="NDR data not found">
						<img src="/image/no-data-neutral.png" style={{ width: "110px", margin: "0px auto" }} />
					</NoData>
				)}
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	ndr_actions: state.track_order_store.ndr_actions,
	select_item: state.track_order_store.select_item
});

const mapDispatchToProps = (dispatch) => ({
	Courier_Track_Order_Clear_Data: () => dispatch(CourierTrackOrderClearData())
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackOrdersNdrActionModal);
