//LOGIN ACTION TYPES
export const LOGIN_USER_GET_DATA = "LOGIN_USER_GET_DATA";
export const LOGIN_SET_ERROR_LIST = "LOGIN_SET_ERROR_LIST";
export const LOGIN_REMOVE_ERROR_LIST = "LOGIN_REMOVE_ERROR_LIST";
export const LOGOUT_RESET_INITIAL_STATE = "LOGOUT_RESET_INITIAL_STATE";
export const LOGIN_SET_EMAIL_OR_PASSWORD = "LOGIN_SET_EMAIL_OR_PASSWORD";

//DASHBOARD ACTIONS
export const CLEAR_DASHBOARD_STORE = "CLEAR_DASHBOARD_STORE";
export const COURIER_DASHBOARD_SET_DATA = "COURIER_DASHBOARD_SET_DATA";

//ANALYTICS ACTIONS
export const ANALYTICS_SET_DATA = "ANALYTICS_SET_DATA";
export const ANALYTICS_SET_FILTER = "ANALYTICS_SET_FILTER";
export const ANALYTICS_RESET_STORE = "ANALYTICS_RESET_STORE";
export const ANALYTICS_SET_COURIER_PARTNERS = "ANALYTICS_SET_COURIER_PARTNERS";
export const CLEAR_ANALYTICS_STORE = "CLEAR_ANALYTICS_STORE";

//COURIER PARTNERS ACTIONS
export const COURIER_PARTNER_SET_DATA = "COURIER_PARTNER_SET_DATA";
export const COURIER_PARTNER_SET_AWBS = "COURIER_PARTNER_SET_AWBS";
export const COURIER_PARTNER_RESET_DATA = "COURIER_PARTNER_RESET_DATA";
export const COURIER_PARTNER_UPDATE_AWBS = "COURIER_PARTNER_UPDATE_AWBS";
export const COURIER_PARTNER_EDIT_DETAILS = "COURIER_PARTNER_EDIT_DETAILS";
export const COURIER_PARTNER_SET_MODAL_DATA = "COURIER_PARTNER_SET_MODAL_DATA";
export const COURIER_PARTNER_REMOVE_ERROR_LIST = "COURIER_PARTNER_REMOVE_ERROR_LIST";
export const COURIER_PARTNER_UPDATE_DETAILS = "COURIER_PARTNER_UPDATE_DETAILS";
export const COURIER_PARTNER_SET_ERROR_LIST = "COURIER_PARTNER_SET_ERROR_LIST";
export const COURIER_PARTNER_RESET_MODAL_DATA = "COURIER_PARTNER_RESET_MODAL_DATA";
export const COURIER_PARTNER_SET_PINCODE_FILE = "COURIER_PARTNER_SET_PINCODE_FILE";

//CHECK PINCODE SCREEN
export const CHECK_SET_PINCODE = "CHECK_SET_PINCODE";
export const CHECK_PINCODE_SET_AVAILABILITY_DATA = "CHECK_PINCODE_SET_AVAILABILITY_DATA";
export const CLEAR_PINCODE_STORE = "CLEAR_PINCODE_STORE";

//CUSTOMERS LOCATION
export const CUSTOMER_UPDATE_LOCATION = "CUSTOMER_UPDATE_LOCATION";
export const CUSTOMER_REJECT_LOCATION = "CUSTOMER_REJECT_LOCATION";
export const CUSTOMER_SET_DELIVERY_LOCATION = "CUSTOMER_SET_DELIVERY_LOCATION";
export const CUSTOMER_LOCATION_SET_LOCATION_DATA = "CUSTOMER_LOCATION_SET_LOCATION_DATA";
export const CUSTOMER_LOCATION_SET_CUSTOMER_DETAILS = "CUSTOMER_LOCATION_SET_CUSTOMER_DETAILS";

//UPDATE PINCODE
export const PINCODE_SET_PINCODE = "PINCODE_SET_PINCODE";
export const PINCODE_SET_CHANGE_DETAIL = "PINCODE_SET_CHANGE_DETAIL";
export const PINCODE_SET_SERVICE_DETAIL = "PINCODE_SET_SERVICE_DETAIL";

//DELIVERY AGENTS
export const DELIVERY_AGENT_SET_DATA = "DELIVERY_AGENT_SET_DATA";
export const DELIVERY_AGENT_SET_SEARCH = "DELIVERY_AGENT_SET_SEARCH";
export const DELIVERY_AGENT_UPDATE_SORT_BY = "DELIVERY_AGENT_UPDATE_SORT_BY";

//TICKET ANSWERS
export const CLEAR_TICKET_ANSWER_STORE = "CLEAR_TICKET_ANSWER_STORE";
export const TICKET_APPEND_DATA = "TICKET_APPEND_DATA";
export const TICKET_SET_ERROR_DATA = "TICKET_SET_ERROR_DATA";
export const TICKET_SET_ACTION_ISSUE = "TICKET_SET_ACTION_ISSUE";
export const TICKET_SET_TICKETS_DATA = "TICKET_SET_TICKETS_DATA";
export const TICKET_SET_RESOLVED_DATA = "TICKET_SET_RESOLVED_DATA";
export const TICKET_CLEAR_ERROR_DATA = "TICKET_CLEAR_ERROR_DATA";
export const TICKET_ANSWER_SET_PAGE_NO = "TICKET_ANSWER_SET_PAGE_NO";
export const TICKET_SET_INITIAL_TICKETS = "TICKET_SET_INITIAL_TICKETS";
export const TICKETS_SET_TICKET_DETAILS = "TICKETS_SET_TICKET_DETAILS";
export const TICKETS_ANSWERS_SET_FILTER_DATA = "TICKETS_ANSWERS_SET_FILTER_DATA";

//COURIER ISSUES
export const CLEAR_COURIER_ISSUES_STORE = "CLEAR_COURIER_ISSUES_STORE";
export const COURIER_ISSUES_SET_PAGE_NO = "COURIER_ISSUES_SET_PAGE_NO";
export const COURIER_ISSUES_APPEND_DATA = "COURIER_ISSUES_APPEND_DATA";
export const COURIER_ISSUES_SET_AWB_DATA = "COURIER_ISSUES_SET_AWB_DATA";
export const COURIER_ISSUES_SET_ERROR_DATA = "COURIER_ISSUES_SET_ERROR_DATA";
export const COURIER_ISSUES_SET_MODAL_DATA = "COURIER_ISSUES_SET_MODAL_DATA";
export const COURIER_ISSUES_SET_ISSUES_DATA = "COURIER_ISSUES_SET_ISSUES_DATA";
export const COURIER_ISSUES_CLEAR_ERROR_LIST = "COURIER_ISSUES_CLEAR_ERROR_LIST";
export const COURIER_ISSUES_SET_FILTER_DATA = "COURIER_ISSUES_SET_FILTER_DATA";
export const COURIER_ISSUES_SET_INITIAL_DATA = "COURIER_ISSUES_SET_INITIAL_DATA";
export const COURIER_ISSUES_CLEAR_SEARCH_AWB = "COURIER_ISSUES_CLEAR_SEARCH_AWB";
export const COURIER_ISSUES_SET_SEARCH_AWB_NUMBER = "COURIER_ISSUES_SET_SEARCH_AWB_NUMBER";
export const COURIER_ISSUES_SET_COURIER_ISSUE_OBJECT = "COURIER_ISSUES_SET_COURIER_ISSUE_OBJECT";

//COURIER NDR
export const CLEAR_COURIER_NDR_STORE = "CLEAR_COURIER_NDR_STORE";
export const COURIER_NDR_SET_DATA = "COURIER_NDR_SET_DATA";
export const COURIER_NDR_SET_PAGE_NO = "COURIER_NDR_SET_PAGE_NO";
export const COURIER_NDR_APPEND_DATA = "COURIER_NDR_APPEND_DATA";
export const COURIER_NDR_SET_NDR_DATA = "COURIER_NDR_SET_NDR_DATA";
export const COURIER_NDR_SET_ERROR_LIST = "COURIER_NDR_SET_ERROR_LIST";
export const COURIER_NDR_SET_CALL_TRIED = "COURIER_NDR_SET_CALL_TRIED";
export const COURIER_NDR_SET_NDR_OBJECT = "COURIER_NDR_SET_NDR_OBJECT";
export const COURIER_NDR_SET_FILTER_DATA = "COURIER_NDR_SET_FILTER_DATA";
export const COURIER_NDR_CLEAR_MODAL_DATA = "COURIER_NDR_CLEAR_MODAL_DATA";
export const COURIER_NDR_SET_AWB_NDR_LIST = "COURIER_NDR_SET_AWB_NDR_LIST";
export const COURIER_NDR_DELETE_ERROR_LIST = "COURIER_NDR_DELETE_ERROR_LIST";
export const COURIER_NDR_SET_ITEM_LIST_DATA = "COURIER_NDR_SET_ITEM_LIST_DATA";
export const COURIER_NDR_SET_UPLOAD_MODAL_DATA = "COURIER_NDR_SET_UPLOAD_MODAL_DATA";
export const COURIER_NDR_SET_ACTION_MODAL_DATA = "COURIER_NDR_SET_ACTION_MODAL_DATA";
export const COURIER_NDR_SET_COURIER_TRACKING_DATA = "COURIER_NDR_SET_COURIER_TRACKING_DATA";

//COURIER REPORTS
export const COURIER_REPORT_SET_FILE = "COURIER_REPORT_SET_FILE";
export const COURIER_REPORT_SET_ERROR_LIST = "COURIER_REPORT_SET_ERROR_LIST";
export const COURIER_REPORT_SET_FILTER_DATA = "COURIER_REPORT_SET_FILTER_DATA";
export const COURIER_REPORT_CLEAR_ERROR_LIST = "COURIER_REPORT_CLEAR_ERROR_LIST";
export const COURIER_REPORT_SET_PARTNER_DATA = "COURIER_REPORT_SET_PARTNER_DATA";
export const COURIER_REPORT_CLEAR_REPORT_FILE = "COURIER_REPORT_CLEAR_REPORT_FILE";

//TRACK ORDERS
export const COURIER_TRACK_SET_DATA = "COURIER_TRACK_SET_DATA";
export const TRACK_ORDER_NDR_ACTION = "TRACK_ORDER_NDR_ACTION";
export const CLEAR_TRACK_ORDER_SCREEN = "CLEAR_TRACK_ORDER_SCREEN";
export const COURIER_TRACK_APPEND_DATA = "COURIER_TRACK_APPEND_DATA";
export const COURIER_TRACK_SET_PAGE_NO = "COURIER_TRACK_SET_PAGE_NO";
export const COURIER_TRACK_SET_AWB_NOTE = "COURIER_TRACK_SET_AWB_NOTE";
export const TRACK_ORDER_SET_MODAL_DATA = "TRACK_ORDER_SET_MODAL_DATA";
export const COURIER_TRACK_SET_FILTER_DATA = "COURIER_TRACK_SET_FILTER_DATA";
export const COURIER_TRACK_ORDER_CLEAR_DATA = "COURIER_TRACK_ORDER_CLEAR_DATA";
export const COURIER_TRACK_SET_TRACKING_DATA = "COURIER_TRACK_SET_TRACKING_DATA";
export const COURIER_TRACK_SET_AWB_OBJECT_ITEM = "COURIER_TRACK_SET_AWB_OBJECT_ITEM";
export const COURIER_TRACK_SET_TRACK_ITEM_LIST_DATA = "COURIER_TRACK_SET_TRACK_ITEM_LIST_DATA";

//COMMON ACTION
export const CLEAR_COMMON_STORE = "CLEAR_COMMON_STORE";
export const COMMON_GET_WEBSITES_DATA = "COMMON_GET_WEBSITES_DATA";
